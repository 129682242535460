var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh", "font-size": "12px" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col"),
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col", { staticStyle: { width: "10%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "6%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "6%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
        _c("col", { staticStyle: { width: "5%" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c("b-th", [_vm._v("ID")]),
              _c("b-th", [_vm._v("활용데이터")]),
              _c("b-th", [_vm._v("제목")]),
              _c("b-th", [_vm._v("게시 시작일")]),
              _c("b-th", [_vm._v("진료과")]),
              _c("b-th", [_vm._v("총 노출 수")]),
              _c("b-th", [
                _vm._v(
                  _vm._s(_vm.searchType === 1 ? "총 노출 처수" : "총 노출 명수")
                ),
              ]),
              _c("b-th", [_vm._v("총 클릭 수")]),
              _c("b-th", [
                _vm._v(
                  _vm._s(_vm.searchType === 1 ? "총 클릭 처수" : "총 클릭 명수")
                ),
              ]),
              _c("b-th", [_vm._v("댓글 수")]),
              _c("b-th", [_vm._v("강력추천")]),
              _c("b-th", [_vm._v("도움백배")]),
              _c("b-th", [_vm._v("눈이번쩍")]),
              _c("b-th", [_vm._v("후속강추")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-tbody",
        [
          _vm._l(_vm.data, function (summary) {
            return [
              _vm._l(summary.summaryDepts, function (dept, index) {
                return [
                  _c(
                    "b-tr",
                    { key: "summary-" + summary.id + "-" + index },
                    [
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(summary.id) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(summary.utilData) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(summary.title) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      summary.startDate,
                                      "YYYY-MM-DD"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("b-td", [_vm._v(_vm._s(dept.medicalDept))]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.totalOpenCount)
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.groupOpenCount)
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.totalClickCount)
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.groupClickCount)
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.commentCount)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.feedback01Count)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.feedback02Count)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.feedback03Count)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.feedback04Count)
                          ) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
              _c(
                "b-tr",
                { key: "summary-total-" + summary.id },
                [
                  _c("b-td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(" 소계 "),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.totalOpenCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.groupOpenCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.totalClickCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.groupClickCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.commentCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.feedback01Count
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.feedback02Count
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.feedback03Count
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.feedback04Count
                          }, 0)
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }